<template>
  <el-col class="page">
    <el-col class="title">发票管理</el-col>
    <el-col class="pane-container">
      // 1为申请中，2为取消，3已受理，4开票中，5已寄出
      <el-col  :class="{ 'pane-active':active=='' }" class="pane cursor-item" @click.native="paneActive('')">全部</el-col>
      <el-col  :class="{ 'pane-active':active==1 }" class="pane cursor-item" @click.native="paneActive('1')">申请中</el-col>
      <el-col  :class="{ 'pane-active':active==3 }" class="pane cursor-item" @click.native="paneActive('3')">处理中</el-col>
      <el-col  :class="{ 'pane-active':active==4 }" class="pane cursor-item" @click.native="paneActive('4')">已寄出</el-col>
    </el-col>
    <el-col class="table-container">
      <el-table
          :data="tableData"
          border
          style="width: 100%"
          v-loading="loading">
        <el-table-column
            prop="rise"
            :formatter ="riseFormatter"
            label="抬头">
        </el-table-column>
        <el-table-column
            prop="state"
            label="状态"
            :formatter ="stateFormatter">
        </el-table-column>
        <el-table-column
            prop="type"
            label="类型"
            :formatter ="typeFormatter">
        </el-table-column>
        <el-table-column
            prop="cost"
            label="金额">
        </el-table-column>
        <el-table-column
            prop="dutyNum"
            label="企业税号">
        </el-table-column>
        <el-table-column
            prop="bankName"
            label="银行名称">
        </el-table-column>
        <el-table-column
            prop="bankNum"
            label="企业银行账号">
        </el-table-column>
        <el-table-column
            prop="regAddress"
            label="企业注册地址">
        </el-table-column>
        <el-table-column
            prop="regPhone"
            label="企业注册电话">
        </el-table-column>
        <el-table-column
            prop="express"
            label="快递信息">
        </el-table-column>
        <el-table-column
            width= "180px"
            prop="name"
            label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" v-if="scope.row.state == 1|| scope.row.state == 2 ? false : true" @click="cancel(scope.row)">取消</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.type == 2 ? false : true" @click="downLoad(scope.row)">下载附件</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 20px">
      <Page :total="page.total"  :current="page.pageNo" :page-size="page.pageSize"
            @on-page-size-change="onPageSizeChange" @on-change="changePage" show-total show-elevator style="font-size: 14px"></Page>
    </el-col>
  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import invoiceApi from "../../../api/buyer/transaction/invoiceApi";
import contractApi from "../../../api/buyer/transaction/contractApi";
Vue.use(iView)
export default {
  name: "invoice",
  data() {
    return {
      loading: true,
      active: 1,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 3
      },
    }
  },
  methods: {
    cancel(row){
      let contractId = [];
      contractId.push(row.invoiceId)
      this.$confirm('确定要取消吗？', '删除提示').then(() => {

        invoiceApi.syncInvoiceDel(contractId).then(res => {
          if(!res.code||res.code !== 200) {
            this.buyerMsg(res.message, 'error');
            return;
          }
          this.buyerMsg('删除成功');
          this.page.pageNo = 1;
          this.getInvoiceInfo(this.active)
        })
      })
    },
    downLoad(row){
      window.location.href = row.enclosureUrl;
    },
    onPageSizeChange(index) {
      this.page.pageSize = index;
      this.getInvoiceInfo(this.active)
    },
    changePage(index) {
      this.page.pageNo = index;
      this.getInvoiceInfo(this.active)
    },
    riseFormatter(row){
      switch (row.rise){
        case 0:
          return '个人';
        case 1:
          return '单位';
        default:
          return '';
      }
    },
    stateFormatter(row){
      //状态：1为申请中，2为取消，3已受理，4开票中，5已取消
      switch (row.state){
        case 1:
          return '申请中';
        case 2:
          return '取消';
        case 3:
          return '已受理';
        case 4:
          return '开票中';
        case 5:
          return '已取消';
        default:
          return '';
      }
    },
    typeFormatter(row){
      //0：电子发票，1:纸质发票
      switch (row.type){
        case 0:
          return '电子发票';
        case 1:
          return '纸质发票';
        default:
          return '';
      }
    },
    paneActive(id) {
      this.active = id;
      this.getInvoiceInfo(id);
    },
    getInvoiceInfo(state){
      this.tableData = [];
      let param = {pageNo: this.page.pageNo, pageSize: this.page.pageSize};
      if(state) {
        param.state = state;
      }
      this.page.total = 0;
      if(this.date){
        var regEx = new RegExp("\\-","gi");
        let dependedVal=this.date.replace(regEx,"/");
        var dependedDate2 = new Date(dependedVal);
        param.createTime = dependedDate2
      }
      invoiceApi.getInvoiceInfo(param).then((res) => {
        this.timer = setTimeout(()=>{   //设置延迟执行
          this.loading = false
        },300)
        if(res.data) {
          this.tableData = res.data.records;
          this.page.total = res.data.total
        }
      })
    },
  },
  mounted() {
    this.paneActive('')
  },
}
</script>

<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
.page {
  margin: 20px 35px;
  width: 97%;
  .title {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .pane-container {
    height: 34px;
    .pane {
      width:48px;
      height: 16px;
      font-size: 16px;
      margin-right: 40px;
      color: rgba(65, 65, 65, 1);
    }
    .pane:active {
      color: #F56E65;
    }
    .pane:hover {
      color: #F56E65;
    }
    .pane-active {
      color: #F56E65;
    }

  }
  .table-container {
    margin-top: 20px;
    /deep/.el-table .cell, .el-table--border .el-table__cell:first-child .cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    /deep/ .el-table thead {
      color: #515A6E;
    }
  }
  /deep/ .ivu-page {
    float: right;
    margin-right: 143px;
  }
}
</style>
