import {get,post} from "@/utils/axios"
// 查询合同
const getInvoiceInfo = p => get('/api-seller/apiconsole/invoice/getInvoiceInfo', p);

const syncInvoiceDel = p => post('/api-seller/apiconsole/invoice/syncInvoiceDel', p);

export default {
    getInvoiceInfo,
    syncInvoiceDel
}
